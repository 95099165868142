<template>
    <v-data-table     
        :headers="tableHeaders"
        :items="items"
        hide-default-footer
        :items-per-page="500"
        mobile-breakpoint="10">

        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>{{ label }}</v-toolbar-title>
                <v-divider v-if="canCreate" class="ml-4" inset vertical />                
                <v-btn v-if="canCreate" color="primary" icon @click="newItem"><v-icon>mdi-plus</v-icon></v-btn>
            </v-toolbar>
        </template>        

        <template v-slot:[`item.actions`]="{ item }">            
            <v-icon v-if="canEdit" color="primary" small class="mx-1" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon v-if="canDelete" color="primary" small class="mx-1" @click="deleteItem(item)">mdi-delete</v-icon>
        </template>

        <template v-for="(filter, index) in displayFilters" v-slot:[`item.${filter}`]="{ item }">
            <td :key="index"><slot :name="filter" v-bind:item="item"></slot></td>            
        </template>

        <v-dialog v-model="dialog">
            <template slot="dialog" v-bind:item="{ currentItem }">

            </template>
        </v-dialog>
    </v-data-table>
</template>

<script>
export default {
    name: 'BT-Table-Basic',
    data: function() {
        return {
            currentItem: null,
            isEditingNewItem: false,
            dialog: false,
        }
    },
    props: {
        onAdd: {
            type: Function,
            default: null
        },
        onDelete: {
            type: Function,
            default: null
        },
        onNew: {
            type: Function,
            default: () => { return { } }
        },
        label: {
            type: String,
            default: null
        },
        headers: {
            type: Array,
            default: () => []
        },
        items: {
            type: Array,
            default: () => []
        },
        canDelete: {
            type: Boolean,
            default: true,
        },
        canEdit: {
            type: Boolean,
            default: true
        },
        canCreate: {
            type: Boolean,
            default: true
        },
        canAct: {
            type: Boolean,
            default: false
        },
        displayFilters: { //for display column data
            type: Array,
            default: () => { return [] }
        },
    },    
    computed: {        
        tableHeaders() {            
            var rHeaders = [];
            for (var i = 0; i < this.headers.length; i++) {
                rHeaders.push(this.headers[i]);
            }

            if (this.includeActions) {
                rHeaders.push({ text: 'Actions', value: 'actions', align: 'right' });
            }
            return rHeaders;
        },
        includeActions() {            
            return this.canDelete || this.canCreate || this.canAct;
        }
    },
    methods: {
        newItem() {
            if (this.onAdd) {
                this.onAdd();
            }
            else {
                this.isEditingNewItem = true;
                this.currentItem = this.onNew();    
                this.dialog = true;        
            }            
        },
        editItem(item) {            
            this.isEditingNewItem = false;
            this.currentItem = item;
            this.dialog = true;            
        },
        endEdit() {
            if (this.isEditingNewItem) {
                this.items.push(this.currentItem);                
            }
            this.isEditingNewItem = false;
            this.currentItem = null;
            this.dialog = false;
        },
        cancelEdit() {
            this.isEditingNewItem = false;
            this.currentItem = null;
            this.dialog = false;
        },
        deleteItem(item) { 
            if (this.onDelete) {
                this.onDelete(item);
            }           
            else {
                if (item.id) {
                    this.items.splice(this.items.findIndex(x => x.id == item.id), 1);
                }
                else {
                    this.items.splice(this.items.findIndex(x => x === item));
                }        
            }            
        },                        
    }
}
</script>